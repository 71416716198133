var WebFont = require("webfontloader");

const INITIAL_STATE = [];

let text = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "ADD_TEXT": {
      return [...state, action.payload];
    }
    case "EDIT_TEXT": {
      let newArray = [...state];
      newArray[action.payload.index] = action.payload.newElement;
      return newArray;
    }
    case "EDIT_ALL_TEXTS": {
      let newArray = [...action.payload];
      return newArray;
    }
    case "EDIT_FONT": {
      let newArray = [...state];
      let newElement = action.payload.newElement;

      WebFont.load({
        google: {
          families: [newElement.fontFamily],
        },
      });

      newArray[action.payload.index] = newElement;
      return newArray;
    }
    case "REMOVE_TEXT": {
      let new_array = state.filter(
        (item, index) => index !== action.payload.index
      );
      return new_array;
    }
    default:
      return state;
  }
};

export default text;
