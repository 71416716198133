var WebFont = require("webfontloader");

export const importGoogleFont = (fontName) => {
  WebFont.load({
    google: {
      families: [fontName],
    },
  });
};

export const toggle = (selector) => {
  let el = document.querySelector(selector);

  if (el.classList.contains("hide")) {
    el.classList.remove("hide");
  } else {
    el.classList.add("hide");
  }
};
