import { useDispatch, useSelector } from "react-redux";
import {
  editText as editTextAction,
  editFont as editFontAction,
  removeText as removeTextAction,
} from "../actions/texts";
import "../assets/css/textsforms.css";
import Header from "./painel/header";

function TextsForms(props) {
  let texts = useSelector((state) => state.texts);
  const dispatch = useDispatch();

  function removeText(i, event) {
    dispatch(removeTextAction({ index: i }));
  }
  //TODO criar o center texto vertical e horizontal
  {
    /* <body>
  <div style="position: absolute; left: 50%;">
    <div style="position: relative; left: -50%; border: dotted red 1px;">
      I am some
    </div>
  </div>
</body> */
  }

  return (
    <div>
      <form>
        {texts.map((text, i) => (
          <>
            <Header
              classToHide={`.text-${i}`}
              trashButton={() => removeText(i)}
              title={texts[i].value == "" ? `Text ${i + 1}` : texts[i].value}
            />

            <div className={`text-${i} section-content section-content-text`}>
              <div className="text-section-1">
                <span>Text</span>
                <input
                  placeholder="Ex: Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                  type="text"
                  value={text.value}
                  onChange={(e) => {
                    texts[i].value = e.target.value;
                    dispatch(
                      editTextAction({
                        index: i,
                        newElement: texts[i],
                      })
                    );
                  }}
                />
              </div>

              <div className="text-section-2">
                <div>
                  <span>Color</span>
                  <input
                    placeholder="Ex: #000000"
                    type="text"
                    value={text.color}
                    onChange={(e) => {
                      texts[i].color = e.target.value;
                      dispatch(
                        editTextAction({
                          index: i,
                          newElement: texts[i],
                        })
                      );
                    }}
                  />
                </div>

                <div className="font-size-section">
                  <span>Font size</span>
                  <input
                    placeholder="Ex: 30px"
                    type="text"
                    value={text.fontSize}
                    onChange={(e) => {
                      texts[i].fontSize = e.target.value;
                      dispatch(
                        editTextAction({
                          index: i,
                          newElement: texts[i],
                        })
                      );
                    }}
                  />
                </div>

                <div className="top-section">
                  <span>Top</span>
                  <input
                    placeholder="Ex: 30px"
                    type="text"
                    value={text.top}
                    onChange={(e) => {
                      texts[i].top = e.target.value;
                      dispatch(
                        editTextAction({
                          index: i,
                          newElement: texts[i],
                        })
                      );
                    }}
                  />
                </div>
              </div>

              <div class="text-section-5">
                <div class="bold">
                  <div>Bold </div>
                  <input type="checkbox" id="scales" name="scales"></input>
                </div>
                <div>
                  <div>Italic</div>
                  <input type="checkbox" id="scales" name="scales"></input>
                </div>
              </div>

              <div className="text-section-3">
                <div>
                  <span>Left</span>
                  <input
                    placeholder="Ex: 10px"
                    type="text"
                    value={text.left}
                    onChange={(e) => {
                      texts[i].left = e.target.value;
                      dispatch(
                        editTextAction({
                          index: i,
                          newElement: texts[i],
                        })
                      );
                    }}
                  />
                </div>

                <div className="max-width">
                  <span>Max Width</span>
                  <input
                    placeholder="Ex: 100px"
                    type="text"
                    value={text.maxWidth}
                    onChange={(e) => {
                      texts[i].maxWidth = e.target.value;
                      dispatch(
                        editTextAction({
                          index: i,
                          newElement: texts[i],
                        })
                      );
                    }}
                  />
                </div>
              </div>

              <div className="text-section-4">
                <div>
                  <span>Font (You can use any Google Font)</span>
                  <input
                    placeholder={`Ex: Lato, Roboto, Poppins`}
                    type="text"
                    value={text.fontFamily}
                    onChange={(e) => {
                      texts[i].fontFamily = e.target.value;
                      dispatch(
                        editFontAction({
                          index: i,
                          newElement: texts[i],
                        })
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        ))}
      </form>
    </div>
  );
}

export default TextsForms;
