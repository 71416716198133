import React, { useState } from "react";
import { toggle } from "../../util";

export default function TroggleButton(props) {
  const [isContentVisible, setIsContentVisible] = useState(true);

  return (
    <>
      {isContentVisible ? (
        <i
          class="fa fa-chevron-up"
          onClick={() => {
            toggle(props.classToHide);
            setIsContentVisible(false);
          }}
        />
      ) : (
        <i
          class="fa fa-chevron-down"
          onClick={() => {
            toggle(props.classToHide);
            setIsContentVisible(true);
          }}
        />
      )}
    </>
  );
}
