import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editAllTexts as editAllTextsAction } from "../actions/texts";
import { editCanvasConfig as editCanvasConfigAction } from "../actions/canvasConfig";
import { editAllImages as editAllImagesAction } from "../actions/images";
import "../assets/css/codeeditor.css";

function CodeEditor(props) {
  let canvasConfig = useSelector((state) => state.canvasConfig);
  let texts = useSelector((state) => state.texts);
  let images = useSelector((state) => state.images);
  let topButtons = useSelector((state) => state.topButtons);

  const [code, setCode] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    setCode(codeGenerator());
  }, [canvasConfig, texts, images]);

  function codeGenerator() {
    let code = { canvasConfig, texts, images };
    code = JSON.stringify(code, null, 2);

    return code;
  }

  function updateJson(codeJson) {
    try {
      let code = JSON.parse(codeJson);
      code = {
        canvasConfig: code.canvasConfig,
        texts: code.texts,
        images: code.images,
      };

      dispatch(editAllTextsAction(code.texts));
      dispatch(editAllImagesAction(code.images));
      dispatch(editCanvasConfigAction(code.canvasConfig));
    } catch (error) {
      alert("Invalid Format.");
    }
  }

  return (
    <div>
      {topButtons.showCodeEditor && (
        <>
          <textarea
            rows="20"
            cols="39"
            value={code}
            onChange={(e) => {
              let code = e.target.value;
              setCode(code);
            }}
          ></textarea>
          <div className="import-buttons">
            <button
              className="button-blue"
              onClick={(e) => {
                e.preventDefault();
                updateJson(code);
              }}
            >
              Import
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default CodeEditor;
