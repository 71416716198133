import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import texts from "./reducers/texts";
import canvasConfig from "./reducers/canvasConfig";
import images from "./reducers/images";
import topButtons from "./reducers/topbuttons.js";
import painel from "./reducers/painel";
import { combineReducers, createStore } from "redux";

const rootReducer = combineReducers({
  texts,
  canvasConfig,
  images,
  topButtons,
  painel,
});
const rootStorage = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

ReactDOM.render(
  //TODO Should I delete scrict mode?
  <React.StrictMode>
    <Provider store={rootStorage}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
