export const addImage = (content) => {
  return {
    type: "ADD_IMAGE",
    payload: content,
  };
};

export const editImage = (content) => {
  return {
    type: "EDIT_IMAGE",
    payload: content,
  };
};

export const removeImage = (content) => {
  return {
    type: "REMOVE_IMAGE",
    payload: content,
  };
};

export const editAllImages = (content) => {
  return {
    type: "EDIT_ALL_IMAGES",
    payload: content,
  };
};
