import React from "react";
import Draggable from "react-draggable";
import { useSelector } from "react-redux";
import "../../assets/css/painel.css";
import { importGoogleFont } from "../../util";
import TroggleButton from "./troggleButton";

export default function Painel(props) {
  //TODO TROCAR ESSA FONT
  importGoogleFont("Roboto Condensed");

  const disableDragPainel = useSelector(
    (state) => state.painel.disableDragPainel
  );

  return (
    <Draggable disabled={disableDragPainel}>
      <div className="painel">
        <div className="header">
          <div class="title">Super Image Api</div>
          <TroggleButton classToHide=".top-buttons" />
          <i class="fa fa-arrows" />
        </div>
        <div className="painel-container">{props.children}</div>
      </div>
    </Draggable>
  );
}
