const INITIAL_STATE = { showCodeEditor: false, showExportCode: false };

let topButtons = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "TROGGLE_SHOW_CODE":
      return { showCodeEditor: !state.showCodeEditor };
    case "TROGGLE_EXPORT_CODE":
      return { showExportCode: !state.showExportCode };
    default:
      return state;
  }
};

export default topButtons;
