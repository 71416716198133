import React from "react";
import { useSelector } from "react-redux";

function Canvas(props) {
  let canvasConfig = useSelector((state) => state.canvasConfig);
  let texts = useSelector((state) => state.texts);
  let images = useSelector((state) => state.images);

  return (
    <div className="preview" style={{ ...canvasConfig }}>
      {/* TODO Refactor */}
      {texts.map((text, i) => (
        <span
          style={{
            "font-size": text.fontSize,
            position: "absolute",
            "max-width": text.maxWidth,
            top: text.top,
            "margin-left": text.left,
            "font-family": text.fontFamily,
            zIndex: 99,
            color: text.color,
          }}
        >
          {text.value}
        </span>
      ))}

      {images.map((image, i) => (
        <img src={image.url} style={{ ...image }} />
      ))}
    </div>
  );
}

export default Canvas;
