import React from "react";

import Canvas from "../components/canvas";
import TextsForms from "../components/textsforms";
import TopButtons from "../components/painel/topButtons";
import ImageForms from "../components/imagesforms";
import ConfigCanvas from "../components/configCanvas";
import CodeEditor from "../components/codeEditor";
import Painel from "../components/painel";
import "../assets/css/editor.css";
import Exportcode from "../components/exportcode";

function Editor() {
  return (
    <>
      <Painel>
        <TopButtons />
        <CodeEditor />
        <Exportcode />
        <ConfigCanvas />
        <TextsForms />
        <ImageForms />
      </Painel>
      <div className="canvas-content">
        <Canvas />
      </div>
    </>
  );
}

export default Editor;
