import { useSelector, useDispatch } from "react-redux";
import { editCanvasConfig as editCanvasConfigAction } from "../actions/canvasConfig";
import Header from "./painel/header";
import "../assets/css/configcanvas.css";

function CanvasConfig(props) {
  let canvasConfig = useSelector((state) => state.canvasConfig);
  const dispatch = useDispatch();

  return (
    <div className="form">
      <form>
        <>
          <Header classToHide={`.config`} title={"Canvas Configs"} />
          <div className="config section-content">
            <div>
              <span>Bg Color</span>
              <input
                placeholder="Ex: #FFFFFF"
                type="text"
                value={canvasConfig.backgroundColor}
                onChange={(e) => {
                  dispatch(
                    editCanvasConfigAction({
                      ...canvasConfig,
                      backgroundColor: e.target.value,
                    })
                  );
                }}
              />
            </div>

            <div>
              <span>Width</span>
              <input
                placeholder="Ex: 500px"
                type="text"
                value={canvasConfig.width}
                onChange={(e) => {
                  dispatch(
                    editCanvasConfigAction({
                      ...canvasConfig,
                      width: e.target.value,
                    })
                  );
                }}
              />
            </div>

            <div>
              <span>Height</span>
              <input
                placeholder="Ex: 500px"
                type="text"
                value={canvasConfig.height}
                onChange={(e) => {
                  dispatch(
                    editCanvasConfigAction({
                      ...canvasConfig,
                      height: e.target.value,
                    })
                  );
                }}
              />
            </div>
          </div>
        </>
      </form>
    </div>
  );
}

export default CanvasConfig;
