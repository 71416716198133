import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { troggleDisableDragPainel } from "../../actions/painel";
import Highlight from "react-highlight.js";
import "../../assets/css/exportcode.css";

function ModalExportCode() {
  const [modalIsOpen, setIsOpen] = useState(false);

  let canvasConfig = useSelector((state) => state.canvasConfig);
  let texts = useSelector((state) => state.texts);
  let images = useSelector((state) => state.images);

  let [rederizedCode, setRenderizeCode] = useState({});

  useEffect(() => {
    renderCode("curl");
  }, [canvasConfig, texts, images]);

  const dispatch = useDispatch();

  function openModal() {
    dispatch(troggleDisableDragPainel());
    setIsOpen(true);
  }

  function closeModal() {
    dispatch(troggleDisableDragPainel());
    setIsOpen(false);
  }

  function codeGenerator() {
    let code = { canvasConfig, texts, images };
    code = JSON.stringify(code, null, 2);

    return code;
  }

  function renderCode(code, event) {
    if (event) {
      event.preventDefault();
    }
    let codeJson = codeGenerator();

    if (code == "curl") {
      let curl = `curl --location --request POST 'https://super-image-api.herokuapp.com/generate-image' \
      --header 'Content-Type: application/json' \
      --data-raw '${codeJson}'`;

      setRenderizeCode({ code: curl, language: "bash" });
    }

    if (code == "nodejs") {
      let nodejs = `var axios = require("axios");

var data = JSON.stringify(${codeJson});

var config = {
  method: "post",
  url: "https://super-image-api.herokuapp.com/generate-image",
  headers: {
    "Content-Type": "application/json",
  },
  data: data,
};
      
axios(config)
  .then(function (response) {
    console.log(JSON.stringify(response.data));
  })
  .catch(function (error) {
    console.log(error);
  });
    `;

      setRenderizeCode({ code: nodejs, language: "javascript" });
    }

    if (code == "python") {
      let python = `import http.client
import json

conn = http.client.HTTPSConnection("super-image-api.herokuapp.com")
payload = json.dumps(${codeJson})

headers = {
  'Content-Type': 'application/json'
}
conn.request("POST", "/generate-image", payload, headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))
      `;

      setRenderizeCode({ code: python, language: "python" });
    }

    if (code == "php") {
      let php = `<?php
$curl = curl_init();

curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://super-image-api.herokuapp.com/generate-image',
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => '',
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => 'POST',
  CURLOPT_POSTFIELDS =>'${codeJson}',
  CURLOPT_HTTPHEADER => array(
    'Content-Type: application/json'
  ),
));

$response = curl_exec($curl);

curl_close($curl);
echo $response;
    
    `;

      setRenderizeCode({ code: php, language: "php" });
    }

    if (code == "JSON") {
      setRenderizeCode({ code: codeJson, language: "json" });
    }

    return "no code";
  }

  return (
    <>
      <button className="button-red" onClick={openModal}>
        Export Code
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div className="export-container">
          <div className="export-buttons">
            <button
              className="button-blue"
              onClick={(event) => {
                renderCode("curl", event);
              }}
            >
              CURL
            </button>
            <button
              className="button-blue"
              onClick={(event) => {
                renderCode("nodejs", event);
              }}
            >
              Node.js
            </button>
            <button
              className="button-blue"
              onClick={(event) => {
                renderCode("python", event);
              }}
            >
              Python
            </button>
            <button
              className="button-blue"
              onClick={(event) => {
                renderCode("php", event);
              }}
            >
              PHP
            </button>
            <button
              className="button-blue"
              onClick={(event) => {
                renderCode("JSON", event);
              }}
            >
              JSON
            </button>
          </div>

          <div>
            <i
              class="fa fa-close fa-2x"
              onClick={() => {
                closeModal();
              }}
            />
          </div>
        </div>

        <Highlight language={rederizedCode.language}>
          {rederizedCode.code}
        </Highlight>
      </Modal>
    </>
  );
}

export default ModalExportCode;
