export const addText = (content) => {
  return {
    type: "ADD_TEXT",
    payload: content,
  };
};

export const editText = (content) => {
  return {
    type: "EDIT_TEXT",
    payload: content,
  };
};

export const editAllTexts = (content) => {
  return {
    type: "EDIT_ALL_TEXTS",
    payload: content,
  };
};

export const editFont = (content) => {
  return {
    type: "EDIT_FONT",
    payload: content,
  };
};

export const removeText = (content) => {
  return {
    type: "REMOVE_TEXT",
    payload: content,
  };
};
