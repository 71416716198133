import { useDispatch, useSelector } from "react-redux";
import {
  editImage as editImageAction,
  removeImage as removeImageAction,
} from "../actions/images";
import Header from "./painel/header";
import "../assets/css/imagesforms.css";

function ImagesForms(props) {
  let images = useSelector((state) => state.images);
  const dispatch = useDispatch();

  function removeImage(i, event) {
    dispatch(removeImageAction({ index: i }));
  }
  return (
    <div className="form">
      <form>
        {images.map((image, i) => (
          <>
            <Header
              classToHide={`.image-${i}`}
              trashButton={() => removeImage(i)}
              title={`Image ${i + 1}`}
            />

            <div className={`image-${i} section-content section-content-image`}>
              <div className="image-section-1">
                <span>URL</span>
                <input
                  placeholder="Ex: http://website.com/image.png"
                  type="text"
                  value={image.url}
                  onChange={(e) => {
                    images[i].url = e.target.value;
                    dispatch(
                      editImageAction({
                        index: i,
                        newElement: images[i],
                      })
                    );
                  }}
                />
              </div>

              <div className="image-section-2">
                <div>
                  <span>Height</span>
                  <input
                    placeholder="Ex: 50px"
                    type="text"
                    value={image.height}
                    onChange={(e) => {
                      images[i].height = e.target.value;
                      dispatch(
                        editImageAction({
                          index: i,
                          newElement: images[i],
                        })
                      );
                    }}
                  />
                </div>

                <div className="width-section">
                  <span>Width</span>
                  <input
                    placeholder="Ex: 50px"
                    type="text"
                    value={image.width}
                    onChange={(e) => {
                      images[i].width = e.target.value;
                      dispatch(
                        editImageAction({
                          index: i,
                          newElement: images[i],
                        })
                      );
                    }}
                  />
                </div>
              </div>

              <div className="image-section-3">
                <div>
                  <span>Top</span>
                  <input
                    placeholder="Ex: 50px"
                    type="text"
                    name="name"
                    value={image.top}
                    onChange={(e) => {
                      images[i].top = e.target.value;
                      dispatch(
                        editImageAction({
                          index: i,
                          newElement: images[i],
                        })
                      );
                    }}
                  />
                </div>

                <div className="left-section">
                  <span>Left</span>
                  <input
                    placeholder="Ex: 50px"
                    type="text"
                    value={image.left}
                    onChange={(e) => {
                      images[i].left = e.target.value;
                      dispatch(
                        editImageAction({
                          index: i,
                          newElement: images[i],
                        })
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        ))}
      </form>
    </div>
  );
}

export default ImagesForms;
