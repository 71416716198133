import React, { useState } from "react";
import "../../assets/css/header.css";
import { toggle } from "../../util";
import TroggleButton from "./troggleButton";

export default function Header(props) {
  const [isContentVisible, setIsContentVisible] = useState(true);

  return (
    <div class="header">
      <div class="title">{props.title}</div>
      <div className="icons">
        {props.trashButton && (
          <i
            class="fa fa-trash"
            onClick={(event) => {
              event.preventDefault();
              props.trashButton();
            }}
          />
        )}

        <TroggleButton classToHide={props.classToHide} />
      </div>
    </div>
  );
}
