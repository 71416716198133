import { useDispatch } from "react-redux";
import { addText as addTextAction } from "../../actions/texts";
import { addImage as addImageAction } from "../../actions/images";
import {
  troggleShowCode as troggleShowCodeAction,
  troggleExportCode as troggleExportCodeAction,
} from "../../actions/topbuttons";
import "../../assets/css/topbuttons.css";
import ModalExportCode from "./modelExportCode";

function TopButtons(props) {
  const dispatch = useDispatch();

  function addText(event) {
    event.preventDefault();

    dispatch(
      addTextAction({
        value: "Text Here",
        fontSize: "100px",
        top: "0px",
        left: "0px",
        maxWidth: "",
        fontFamily: "",
        color: "#000000",
      })
    );
  }

  function addImage(event) {
    event.preventDefault();

    dispatch(
      addImageAction({
        url: "https://superimageapi.weslei.com/placeholder.png",
        position: "absolute",
        top: "0px",
        left: "0px",
        height: "150px",
        width: "150px",
      })
    );
  }

  function troggleShowCode(event) {
    event.preventDefault();
    dispatch(troggleShowCodeAction());
  }

  function troggleExportCode(event) {
    event.preventDefault();
    // dispatch(troggleExportCodeAction());
  }

  return (
    <div className="top-buttons">
      <button className="button-blue" onClick={addText}>
        Add Text
      </button>
      <button className="button-blue" onClick={addImage}>
        Add Image
      </button>
      <ModalExportCode />
      <button className="button-blue" onClick={troggleShowCode}>
        Import/Export JSON
      </button>
      {/* <button className="button-red" onClick={troggleShowCode}>
        How to use that?
      </button> */}
    </div>
  );
}

export default TopButtons;
