const initialState = { disableDragPainel: false };

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "TROGGLE_DISABLE_DRAG_PAINEL":
      return { disableDragPainel: !state.disableDragPainel };

    default:
      return state;
  }
};
