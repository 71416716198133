const INITIAL_STATE = {
  height: "400px",
  width: "400px",
  backgroundColor: "#FFF",
};

let text = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "EDIT_CONFIG":
      return action.payload;
    default:
      return state;
  }
};

export default text;
