const INITIAL_STATE = [];

let images = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "ADD_IMAGE": {
      return [...state, action.payload];
    }
    case "EDIT_IMAGE": {
      let newArray = [...state];
      newArray[action.payload.index] = action.payload.newElement;
      return newArray;
    }
    case "EDIT_ALL_IMAGES": {
      let newArray = [...action.payload];
      return newArray;
    }
    case "REMOVE_IMAGE": {
      let new_array = state.filter(
        (item, index) => index !== action.payload.index
      );
      return new_array;
    }
    default:
      return state;
  }
};

export default images;
