export const troggleShowCode = (content) => {
  return {
    type: "TROGGLE_SHOW_CODE",
    payload: content,
  };
};

export const troggleExportCode = (content) => {
  return {
    type: "TROGGLE_EXPORT_CODE",
    payload: content,
  };
};
