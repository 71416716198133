import React from "react";
import Editor from "./screens/editor";
import "./App.css";
function App() {
  return <Editor />;
}

//Wake up heroku!!
fetch("https://super-image-api.herokuapp.com/").then();

export default App;
